<template>
    <div class="has-space">
        <MatchContent  ref="matchContentChild"  :isStatusWidthAuto="true" :isShowStatusAtTop="true" ></MatchContent>
    </div>
</template>

<script>
import MatchContent from '@/components/MatchContent.vue'
import config from '@/js/config.js'

import {mapGetters,mapActions} from 'vuex'
export default {
    components: {
        MatchContent,
    },
    data() {
        return {
            matchContentInterval : null
        }
    },
    created() {
      this.teamKey = this.$route.params.teamKey;
    },
    beforeUnmount() {
        clearInterval(this.matchContentInterval)
   },
    mounted() {
        this.init();
    },
    watch:{
        currentLocale: {
             deep:true,
            handler(newVal,oldVal) {
             this.init();
               // this.getLeagueListForRankingTable(newVal.leagueIdList);
            }
        },
    },
   computed:{
        ...mapGetters([
            'currentLocale',
        ]),  
    },
 

    methods: {
        init() {
            this.commitParamsToMatchContent();
            this.commitParamsToMatchContentIntervel();
        },
        commitParamsToMatchContentIntervel(){ 
            if (this.matchContentInterval) {
                clearInterval(this.matchContentInterval);
            }
            this.matchContentInterval = setInterval(() => {
                this.commitParamsToMatchContentInterval();              
            }, config.intervalMatchList);     
        
        },

        commitParamsToMatchContentInterval(){
            let params = {
                teamId : this.teamKey,
                isCallInterval : true
            }

            this.$refs.matchContentChild.getMatchListByTeamData(params);
        }, 

        commitParamsToMatchContent(){
            let params = {
                teamId : this.teamKey,
                isCallInterval : false
            }
            this.$refs.matchContentChild.getMatchListByTeamData(params);
        }
    },
}
</script>

<style>

</style>